import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useForm, ValidationError } from '@formspree/react';
import { Link, useNavigate } from 'react-router-dom';

const ContactForm = () => {
    const [state, handleSubmit] = useForm("mvojdnra");
    const [captchaPassed, setCaptchaPassed] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const contactForm = (key) => {
        return t(`contactForm.${key}`);
    };
    const formRef = React.useRef(null);

    const handleTencentCaptcha = () => {
        const captcha = new window.TencentCaptcha('198760771', window.callback);
        captcha.show();
    };

    useEffect(() => {
        window.callback = (res) => {
            if (res.ret === 0) {
                setCaptchaPassed(true);
            } else {
                console.log('Form reference is invalid');
            }
        };
    }, [captchaPassed]);



    const handleVerifyClick = (e) => {
        e.preventDefault();
        handleTencentCaptcha();
    };

    const refreshQuoteHandler = () => {
        navigate(0);
        const form = document.getElementById("wpo-contact-form-area");
        if (form) {
            form.scrollIntoView({ behavior: "smooth" });
        }
    }


    if (state.succeeded) {
        return <div className="thank-you-container">
            <div className="checkmark">
                ✔
            </div>
            <h1>{contactForm("Thankyou")}</h1>
            <p>{contactForm("WehaveReceivedYourMessage")}</p>
            <ul>
                <li><Link to="/contact" onClick={refreshQuoteHandler}>{contactForm("SubmitAnother")}</Link></li>
                <li><Link to="/home">{contactForm("GobackHome")}</Link></li>
            </ul>
        </div>;
    }

    return (
        <form ref={formRef} onSubmit={handleSubmit} className="contact-validation-active" >
            <div className="row">
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <label htmlFor="name"><b className='required'>{contactForm("FullName")}</b></label>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            placeholder={contactForm("YourName")}
                            required
                        />
                        <ValidationError
                            prefix="Name"
                            field="name"
                            errors={state.errors}
                        />
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <label htmlFor="email" ><b className='required'>{contactForm("Email")}</b></label>
                        <input
                            id="email"
                            type="email"
                            name="email"
                            placeholder={contactForm("YourEmail")}
                            required
                        />
                        <ValidationError
                            prefix="Email"
                            field="email"
                            errors={state.errors}
                        />
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <label htmlFor="phone"><b className='required'>{contactForm("Phone")}</b></label>
                        <input
                            id="phone"
                            type="tel"
                            name="phone"
                            placeholder={contactForm("YourPhone")}
                            required
                        />
                        <ValidationError
                            prefix="Phone"
                            field="phone"
                            errors={state.errors}
                        />
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <label htmlFor="company"><b>{contactForm("Company")}</b></label>
                        <input
                            id="company"
                            type="text"
                            name="company"
                            placeholder={contactForm("YourCompany")} />
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <label htmlFor="referral"><b className='required'>{contactForm("HowDidYouFindUs")}</b></label>
                        <select
                            type="text"
                            id="referral"
                            name="referral">
                            <option>{contactForm("Choose")}</option>
                            <option>{contactForm("ExistingCustomer")}</option>
                            <option>{contactForm("Google")}</option>
                            <option>{contactForm("Advertisement")}</option>
                            <option>{contactForm("WordofMouth")}</option>
                            <option>{contactForm("Other")}</option>
                        </select>
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <label htmlFor="callTime"><b>{contactForm("BestTimetoCall")}</b></label>
                        <select
                            id="callTime"
                            type="text"
                            name="callTime">
                            <option>{contactForm("AnyTime")}</option>
                            <option>{contactForm("Morning")}</option>
                            <option>{contactForm("Afternoon")}</option>
                        </select>
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <label htmlFor="message"><b className='required'>{contactForm("Message")}</b></label>
                    <textarea
                        id="message"
                        type="text"
                        name="message"
                        placeholder={contactForm("MessagePlaceHolder")}
                        required
                    >
                    </textarea>
                    <ValidationError
                        prefix="Message"
                        field="message"
                        errors={state.errors}
                    />
                </div>
            </div>

            <div className="submit-area">
                <div className="submit-area">
                    {!captchaPassed &&
                        <button type="button" className="theme-btn" onClick={handleVerifyClick}>
                            {contactForm("Verify")}
                        </button>
                    }
                    {captchaPassed &&
                        <button type="submit" className="theme-btn" disabled={state.submitting}>
                            {contactForm("SubmitNow")}
                        </button>
                    }
                </div>
            </div>
        </form>
    )
}

export default ContactForm;