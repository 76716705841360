const en_translation = {
    Home: "Home",
    AboutUs: "About Us",
    Products: "Products",
    Blog: "Blog",
    Contact: "Contact",
    ContactUs: "Contact Us",
    SetsOfElectricalEquipments: "Sets of Electrical Equipments",
    Transformers: "Transformers",
    PrefabricatedSubstations: "Prefabricated Substations",
    IronAccessories: "Iron Accessories",
    AnnualCapacity: "Annual Capacity",
    heroTitle: "Transforming Power with Integrity and Innovation",
    customized: "Customized",
    ourProducts: "Our Products",
    detail: "Detail",
    QUALITYASSURANCE: "QUALITY ASSURANCE",
    QualificationCertificates: "Qualification Certificates",
    BlogPost: "Blog Post",
    LatestNewsFromBlog: "Latest News From Blog",
    HONORABLECUSTOMER: "HONORABLE CUSTOMER",
    Weareworkwithtopbrand: "We are work with top brand",
    ProductCategory: "Product Category",
    AllProducts: "All Products",
    OperatingConditions: "Operating Conditions",
    Features: "Features",
    technicalParameters: "technical Parameters",
    Product: "Product",
    Type: "Type",
    Model: "Model",
    Design: "Design",
    Duration: "Duration",
    UpdateDate: "Update Date",
    RelatedProducts: "Related Products",
    ProductOverview: "Product Overview",
    LatestNews: "Latest News",
    READMORE: "READ MORE...",
    Back: "Back",
    By: "By",
    Share: "Share: ",
    facebook: "facebook",
    twitter: "twitter",
    linkedin: "linkedin",
    pinterest: "pinterest",
    PreviousPost: "Previous Post",
    Thisisalreadythefirstpost: "This is already the first post.",
    NextPost: "Next Post",
    Tags: "Tags: ",
    NoResults: "Your search did not match any blogs",
    NoResultsHints: "Suggestions:",
    Hint1: "Make sure that all words are spelled correctly.",
    Hint2: "Try tags at the right side.",
    singleResult: " result",
    multipleResults: " results",
    Thisisalreadythelastpost: "This is already the last post.",
    RelatedPosts: "Related Posts",
    SearchHere: "Search Here",
    SearchPost: "Search Post..",
    lengthError: "keyword is empty or too long",
    typeError: "Keyword includes invalid character",
    by: "by: ",
    OopsPageNotFound: "Oops! Page Not Found!",
    SorryNotFound: "We’re sorry but we can’t seem to find the page you requested. This might be because you have typed the web address incorrectly.",
    BacktoHome: "Back to home",
    NotSupportVideo: "Your browser does not support HTML5 video.",
    SomethingWentWrong: "Something went wrong!",
    ContactAdministratorOrGoBackHome: "Please contact the administrator or go back to home page.",
    contactUspage: {
        AddressTitle: "Address",
        SpecificAddress: "No.1 Yansha Road, Yanji Diamond Industrial Zone, Yanji Town, Ezhou City, Hubei Province, China.",
        ChatwithUs: "Chat with Us",
        Email: "Email: ",
        WeChat: "WeChat",
        CallUs: "Call Us",
        Tel: "Tel: ",
        Fax: "Fax: ",
        Phone: "Phone: ",
        OnlineQuote: "Online Quote",
        OnlineQuoteDescription: "To help us provide you with the best electrical solution to your needs quickly, please provide as much information as possible and we will get back to you promptly.",
    },
    contactForm: {
        FullName: "Full Name",
        YourName: "Your Name",
        Email: "Email",
        YourEmail: "Your Email",
        Phone: "Phone",
        YourPhone: "Your Phone",
        Company: "Company / Institution",
        YourCompany: "Your Company / Institution",
        HowDidYouFindUs: "How Did You Find Us",
        Choose: "Choose",
        ExistingCustomer: "Existing Customer",
        Google: "Google",
        Advertisement: "Advertisement",
        WordofMouth: "Word of Mouth",
        Other: "Other",
        BestTimetoCall: "Best Time to Call",
        AnyTime: "Any Time",
        Morning: "Morning",
        Afternoon: "Afternoon",
        Message: "Message",
        MessagePlaceHolder: "If you have any special requirements, let us know and we will get back to you soon.",
        SubmitNow: "Submit Now",
        Verify: "I am not a robot",
        SubmitError: "Oops! There was a problem submitting your form, please contact us by telephones above",
        SubmitSuccess: "Thanks for your submission! We'll get back to you soon",
        VerificationError: "Verification code loading error",
        Thankyou: "Thank you!",
        WehaveReceivedYourMessage: "We have receivied your message and will get back to you as soon as possible!",
        SubmitAnother: "Submit another quote",
        GobackHome: "Go back to home page"
    },
    missionVissionPage: {
        transformersSubstationsTitle: "Our Transformers and Substations in Hubei and Beyond",
        clienteleSatisfaction: "Current Clientele and Customer Satisfaction",
        currentCustomersDescription: "Our current customers include CSCEC (China State Construction Engineering Corporation), MCC (China First Metallurgical Group), CACCC (China Railway Beijing Engineering Group), State Grid and many others. All have expressed a high level of satisfaction with our products and services.",
        operationalLandmarksDescription: "Our transformers and prefabricated substation are prominently operational across several landmarks in Ezhou, such as:",
        customTransformersInfo: "We have also manufactured custom transformers and substations such as 1250kVA 33/0.4kV Box-type Substation, 750kVA 12/0.44kV American-style Transformer and 440V Low Voltage Switchgear, 400kVA 33/0.4kV Oil-immersed Transformer, Voltage Transformer and 800kVA 15/0.4kV Oil-immersed Transformer for European, African and South Asian customers.",
        qualityCornerstoneTitle: "Quality Forms the Cornerstone of Our Brand",
        businessTrustSubtitle: "A business that you can trust",
        ximaiQualityImportance: "Ximai Electric understands that the key factor for sustainable business growth is quality. Ximai Electric adopts the 6S management model and employs exquisite craftsmanship to produce high-quality products that meet the needs of a wide range of customers. Ximai Electric's quality management system complies with ISO9001, its environmental management system meets ISO4001 standards, and its occupational health and safety management system is OHSAS18001 certified. Its low-voltage complete set equipment products have received 3C certification, and all other products have passed the qualified tests of the National Quality Supervision and Inspection Center, along with holding national patents. Furthermore, we have been approved as a qualified supplier by the State Grid, Ximai Electric has achieved substantial success in various project bids within the State Grid since 2006.",
        safetyFirstQualityOriented: "With \"Safety First, Quality-Oriented, Customer-Centric\" as our mission, Ximai Electric has been honored for multiple consecutive years with the \"Contract-Abiding and Trustworthy Enterprise\" award in both Hubei Province and the city of Ezhou. Additionally, the Ximai Electric logo has been recognized as a famous trademark in both Hubei Province and the city of Ezhou.",
        landmarks: [
            'Ezhou Central Hospital',
            'Huahu Airport, Ezhou',
            'Ezhou Police Station',
            'Ezhou CDC (Chinese Center for Disaese Control and Prevention)',
            "Ezhou Aerotropolis People's Hospital",
            'Ezhou Third Hospital',
            'Ezhou University'
        ],
    },
    About4: {
        title: "Everything about Our Company",
        description1: "Located in the ancient city of Ezhou, Ximai Electrical Equipment Co., Ltd. (\"Ximai Electrical\") was established in 2006. Our facility covers 40,000 square meters, with a 20,000-square-meter factory floor. We boast an annual production capacity of 18,000 sets of high and low-voltage equipment, 9,000 transformers, 4,500 prefabricated substations, and 10,000 tons of iron accessories. Holding foreign trade rights, Ximai Electrical utilizes its smart manufacturing capabilities and brand strength to distribute products across China and international markets, including Asia and Africa.",
        description2: "We specialize in the production of 10KV energy-saving transformers, available in dry-type, oil-immersed, and amorphous alloy variations. Our product line also includes <span>electric vehicle charging stations</span>, <span>low-voltage switchboards</span> such as GGD, GCS, and GGJ, as well as <span>high-voltage cabinets</span> like KYN-28 and HXGN-12. In addition, we offer <span>high-voltage ring main units</span>, both high and low-voltage metering cabinets, and <span>European and American styles of charging station transformers</span>.",
        button: "Enquire Now"
    },
    testimonial: {
        sectionSubtitle: "INTERNATIONAL CLIENT TESTIMONIAL",
        sectionTitle: "Best Word Our Clients",
        words: {
            '01': {
                Des: "Working with Ximai Electrical was an exceptional experience. We inquired about voltage transformers and received not only a competitive quote but also detailed blueprints. We placed the order without a hitch, and received high-quality products in September 2019, right on schedule. Highly recommended!",
                Title: 'Pavel Livandovskyy',
                Sub: "HIGH VOLTAGE UNION-RZVA"
            },
            '02': {
                Des: "We searched for suppliers online and visited multiple factories, but Ximai Electrical truly stood out. Their competitive pricing and strong capabilities impressed us. After confirming the blueprints, we placed an order for a 1250kVA 33/0.4kV substations. The delivery arrived in August 2019, exactly as planned. Couldn't be more satisfied!",
                Title: 'Henos Dereb',
                Sub: "Manager"
            },
            '03': {
                Des: "We found Ximai Electrical online and their professionalism caught our eye immediately. Not only did they offer competitive pricing, but their service level was also exceptional. We had multiple options but their reputation sealed the deal. Received shipment and all processes are smooth.",
                Title: 'Jesse Mouttet',
                Sub: "Merketer"
            }
        }
    },
    footer: {
        address: "No.1 Yansha Road, Yanji Diamond Industrial Zone, Yanji Town, Ezhou City, Hubei Province, China.",
        tel: "Tel: (0086) 0711-5921007",
        fax: "Fax: (0086) 0711-5921066",
        email: "Email: hbxmdq@163.com",
        company: "Company",
        aboutCompany: "About company",
        products: "Our Products",
        contactUs: "Contact us",
        visionValues: "Vision & Values",
        recentPost: "Recent Post",
        copyright: "Copyright &copy; 2022 //  All Right Reserved by",
        history: "History",
        awards: "Awards",
        leadership: "Leadership",
        ContactUsNow: "Contact Us Now"
    },
    HeaderTopbar3: {
        address: "1 Yansha Road, Ezhou"
    },
    AboutHome: {
        heroTitle1: "We are",
        heroTitleHighlight1: "designing",
        heroTitle2: "and",
        heroTitleHighlight2: "manufacturing",
        heroTitle3: "transformers",
        heroTitle4: "and",
        heroTitleHighlight3: "electrical equipments",
        description: "Ximai spans 40,000 square meters with a newly built factory of 20,000 square meters. It has an annual production capacity of 8,000 sets of high and low voltage equipment, 6,000 transformers, 2,000 box-type substations, and 10,000 tons of iron accessories. The company's intelligent energy-saving products and core technologies are improving year by year. Ximai has achieved impressive results in the National Grid, power generation, transportation, and intelligent manufacturing sectors, and its equipment products have received unanimous praise from relevant national departments and users.",
        exploreBtn: "Explore"
    },
    service: {
        "transformerTitle": "Transformer",
        "transformerDescription": "Including dry-type, oil-immersed and amorphous alloy transformer",
        "switchgearTitle": "Switchgear",
        "switchgearDescription": "Including KYN28, GCS, KYN28A-12 and GGD type.",
        "substationTitle": "Prefabricated Substation",
        "substationDescription": "Box-type Substation (for both European and American style).",
        "accessoriesTitle": "Accessories",
        "accessoriesDescription": "Including electric iron, aerial insulation, transformer and line fittings, etc."
    },
    Qualification: {
        "1": {
            "title": "WSF Certificate",
            "subTitle": "Transformers, Substations, Switchgear and Iron accessories Registration"
        },
        "2": {
            "title": "WSF Certificate",
            "subTitle": "Transformers, Substations, Switchgear and Iron accessories Registration"
        },
        "3": {
            "title": "WSF Certificate",
            "subTitle": "Transformers, Substations, Switchgear and Iron accessories Registration"
        },
        "4": {
            "title": "CCC Certificate",
            "subTitle": "Quality Assurance of Power Distribution in Public Networks"
        },
        "5": {
            "title": "CCC Certificate",
            "subTitle": "Quality Assurance of Low-voltage Switchgear Assemblies"
        },
        "6": {
            "title": "CCC Certificate",
            "subTitle": "Quality Assurance of Low-voltage Switchgear Assemblies"
        },
        "7": {
            "title": "CCC Certificate",
            "subTitle": "Quality Assurance of Distribution Boards"
        },
        "8": {
            "title": "CCC Certificate",
            "subTitle": "Quality Assurance of Low-voltage Switchgear Assemblies"
        },
        "9": {
            "title": "CCC Certificate",
            "subTitle": "Quality Assurance of Low-voltage Switchgear Assemblies"
        }
    },
    Patents: {
        '1': {
            "title": "Appearance Patent",
            "subTitle": "Distribution Transformer"
        },
        '2': {
            "title": "Utility Model Patent",
            "subTitle": "Power Distribution Box P1"
        },
        '3': {
            "title": "Utility Model Patent",
            "subTitle": "Power Distribution Box P2"
        },
        '4': {
            "title": "Utility Model Patent",
            "subTitle": "Low Voltage Switchgear P1"
        },
        '5': {
            "title": "Utility Model Patent",
            "subTitle": "Low Voltage Switchgear P2"
        },
        '6': {
            "title": "Utility Model Patent",
            "subTitle": "Close Structure Switchgear P1"
        },
        '7': {
            "title": "Utility Model Patent",
            "subTitle": "Close Structure Switchgear P2"
        },
        '8': {
            "title": "Utility Model Patent",
            "subTitle": "Lightning Protection Type Transformer Cabinet P1"
        },
        '9': {
            "title": "Utility Model Patent",
            "subTitle": "Lightning Protection Type Transformer Cabinet P2"
        },
        '10': {
            "title": "Utility Model Patent",
            "subTitle": "waterproof type Box type substation p1"
        },
        '11': {
            "title": "Utility Model Patent",
            "subTitle": "waterproof type Box type substation p2"
        },
        '12': {
            "title": "Utility Model Patent",
            "subTitle": "Shock absorbing type transformer p1"
        },
        '13': {
            "title": "Utility Model Patent",
            "subTitle": "Shock absorbing type transformer p2"
        },
        '14': {
            "title": "Utility Model Patent",
            "subTitle": "Detachable type Transformer bottom frame p1"
        },
        '15': {
            "title": "Utility Model Patent",
            "subTitle": "Detachable type Transformer bottom frame p2"
        },
        '16': {
            "title": "Utility Model Patent",
            "subTitle": "Cooling structure of new dry-type transformer p1"
        },
        '17': {
            "title": "Utility Model Patent",
            "subTitle": "Cooling structure of new dry-type transformer p2"
        },
        '18': {
            "title": "Utility Model Patent",
            "subTitle": "Assembled multifunctional low voltage distribution box p1"
        },
        '19': {
            "title": "Utility Model Patent",
            "subTitle": "Assembled multifunctional low voltage distribution box p2"
        }
    },
    ProductCategories: {
        "PrefabricatedSubstation": "Prefabricated Substation",
        "Switchgear": "Switchgear",
        "Transformer": "Transformer",
        "showcategories": "Show Categories",
        "CT&PT": "CT&PT",
    },
    products: {
        0: {
            "name": "European-style Prefabricated Box-type Substation",
            "model": "YB£-12/0.4-£",
            "category": "Prefabricated Substation",
            "shortDescription": "Widely Used in urban areas, high-rises, industrial sites, highways, oil fields and various public spaces for temporary power.",
            "intro": "Widely used in urban grid renovation, residential areas, high-rise buildings, industrial and mining, hotels, shopping malls, airports, railways, oil fields, docks, highways, and other indoor and outdoor locations for temporary power facilities.",
            duration: "7 days",
            updateDate: "27 Sept, 2023",
            "features": {
                "0": "Integration of high-voltage switchgear, transformers, and low-voltage switchgear, providing a complete set",
                "1": "Comprehensive high and low voltage protection, safe and reliable operation, and easy maintenance",
                "2": "Compact footprint, cost-effective, short production cycle, and easily mobile",
                "3": "Flexible wiring options",
                "4": "Unique Structure: Unique honeycomb double-layer (composite board) shell that is sturdy, heat-insulating, ventilating, aesthetically pleasing, and offers a high level of protection. Shell materials include stainless steel, aluminum alloy, cold-rolled plate, and colored steel plate options",
                "5": "Various Types: General type, villa type, compact type, etc.",
                "6": "The high-voltage ring network cabinet can be equipped with a network automation terminal (FTU) for reliable detection of short circuits and single-phase ground faults. Equipped with 'four remote' functions, facilitating the upgrading of distribution network automation"
            },
            "condition": {
                "0": "Altitude not exceeding 1000m",
                "1": "Ambient temperature: -25℃~+40℃",
                "2": "Relative humidity: Daily average value not exceeding 95%, monthly average value not exceeding 90%",
                "3": "Installation locations: Locations with no risk of fire, explosion, conductive dust, corrosive gases, and severe vibrations. If the conditions exceed these, the user may consult with our company"
            }
        },
        "1": {
            "name": "American-style Prefabricated Box-type Substation",
            "model": "YB27-12",
            "category": "Prefabricated Substation",
            "shortDescription": "Advanced tech meets local needs in our compact, easy-to-install product, offering low noise, high capacity, and full protection for various settings.",
            "intro": "This product is developed by absorbing the latest advanced technology from abroad and combining it with domestic practical situations. It features small size, easy installation and maintenance, low noise, low loss, anti-theft, high overload capacity, and full protection. It is suitable for newly built residential areas, green belts, parks, station hotels, construction sites, airports, etc.",
            duration: "10 days",
            updateDate: "27 Sept, 2023",
            "features": {
                "0": "Fully insulated, fully sealed, maintenance-free, and ensures personal safety",
                "1": "Compact structure, its volume is only 1/3-1/5 of European substations of the same capacity, with lower height",
                "2": "The split box structure can be adopted to avoid pollution of transformer oil",
                "3": "High-voltage side uses dual fuses for full-range protection, significantly reducing costs",
                "4": "Suitable for both ring networks and terminals, and the cable head can be urgently inserted and removed under a load current of 200A",
                "5": "The box adopts a honeycomb double-layer composite board, which has heat insulation and heat dissipation functions",
                "6": "The low-voltage side is equipped with an electronic phase-loss protector, which can quickly disconnect the main incoming switch when an abnormal voltage occurs in the system",
                "7": "The high-voltage side has oil-immersed load switches or SF6 load switches, which can be electrically upgraded to lay the foundation for distribution network automation",
                "8": "Uses oil-immersed S9 transformers or better-performing S11 series transformers"
            },
            "condition": {
                "0": "Altitude not exceeding 1000m",
                "1": "Ambient temperature: -35℃~+40℃",
                "2": "Relative humidity: Daily average value not exceeding 95%, monthly average value not exceeding 90%",
                "3": "Installation locations: Locations without the risk of fire, explosion, corrosive gases, and well-ventilated areas, ground inclination not exceeding 3°"
            }
        },
        "2": {
            "name": "Armored Movable Enclosed Switchgear",
            "model": "KYN28A-12",
            "category": "Switchgear",
            "duration": "3 days",
            "updateDate": "27 Sept, 2023",
            "type": "Indoor AC Metal-Clad Mid-mounted Switchgear",
            "shortDescription": "Compact, easy-to-install AC metal-clad product with low noise and high capacity, ideal for new residential areas and public spaces.",
            "intro": "KYN28A-12 type indoor AC metal-clad mid-mounted switchgear is suitable for three-phase AC systems with a rated voltage of 12kV and a rated frequency of 50Hz. It is used for receiving and distributing electrical energy and for controlling, protecting, and monitoring circuits.",
            "features": {
                "0": "The switchgear is designed according to GB3906-91 for armored metal-enclosed switchgear. It is mainly composed of a cabinet and mid-mounted drawable parts (i.e., trolleys). The cabinet is divided into four separate compartments, with an outer shell protection level of IP4X. The protection level between the compartments and when the circuit breaker chamber door is open is IP2X.",
                "1": "It provides overhead inlets and outlets, cable inlets and outlets, and other functional schemes. After being arranged and combined, it forms a complete power distribution system device.",
                "2": "Installation, debugging, and maintenance of this switchgear can be performed from the front. Therefore, it can be back-to-back, form double rows, and be installed against the wall, improving the safety and flexibility of the switchgear and reducing its footprint."
            },
            "condition": {
                "0": "Ambient air temperature: Maximum temperature +40℃, minimum temperature -15℃",
                "1": "Relative humidity: Daily average relative humidity: ≤95%; daily average water vapor pressure does not exceed 2.2KPa; monthly average relative humidity: <90%; monthly average water vapor pressure does not exceed 1.8KPa",
                "2": "Altitude: Below 1000m",
                "3": "Seismic intensity: Not exceeding 8 degrees",
                "4": "The surrounding air should not be significantly polluted by corrosive or flammable gases, water vapor, etc.",
                "5": "No severe vibration locations",
                "6": "If used under conditions beyond those stipulated by GB3906, it should be negotiated between the user and the company."
            }
        },
        "3": {
            "name": "Low Voltage Switchgear",
            "model": "GGD",
            "category": "Switchgear",
            "duration": "3 days",
            "updateDate": "27 Sept, 2023",
            "shortDescription": "High-capacity AC cabinet for energy conversion and distribution in various sectors; features flexibility and high protection.",
            "type": "AC Low Voltage Power Distribution Cabinet",
            "intro": "The GGD AC low voltage power distribution cabinet is suitable for power plants, substations, industrial and mining enterprises, and other electricity users with a 50Hz AC system, rated working voltage of 380V, and rated working current of 5000A. It is used for the conversion, distribution, and control of electrical energy for power, lighting, and distribution equipment. The product has high breaking capacity, good dynamic thermal stability, flexible electrical schemes, convenient combination, strong practicality, novel structure, and high protection level. It can be used as an upgraded product for low-voltage switchgear.",
            "features": {
                "0": "High breaking capacity and good dynamic thermal stability",
                "1": "Flexible electrical schemes and convenient combinations",
                "2": "Strong practicality, novel structure, and high protection level",
                "3": "Can be used as an upgraded product for low-voltage switchgear"
            },
            "condition": {
                "0": "Ambient air temperature not higher than +40℃ and not lower than -5℃. The average temperature within 24 hours should not exceed +35℃",
                "1": "Indoor installation, the altitude of the installation location should not exceed 2000m",
                "2": "Relative humidity not exceeding 50% at the highest temperature of +40℃, and a larger relative humidity is allowed at lower temperatures (e.g., 90% at +20℃). The impact of condensation due to temperature changes should be considered",
                "3": "The inclination of the equipment when installed should not exceed 5%",
                "4": "The equipment should be installed in a place without severe vibration and impact, and where the electrical components are not subject to corrosion",
                "5": "For special requirements, negotiate with the manufacturer"
            },
            table: {
                headers: [
                    "Model",
                    "Rated Voltage (V)",
                    "Rated Current (A)",
                    "Rated Short-Circuit Breaking Current (KA)",
                    "Rated Short-Time Withstand Current (1S) (KA)",
                    "Rated Peak Withstand Current (KA)"
                ],
                rows: [
                    ["CCD1", "380", "A1000", "15", "15", "30"],
                    ["", "", "B600(630)", "", "", ""],
                    ["", "", "C400", "", "", ""],
                    ["GGD2", "380", "A1500(1600)", "30", "30", "63"],
                    ["", "", "B1000", "", "", ""],
                    ["", "", "C600", "", "", ""],
                    ["GGD3", "380", "A3200", "50", "50", "105"],
                ],
            },
        },
        "4": {
            "name": "Low Voltage Reactive Power Compensation Device",
            "model": "GGJ",
            "category": "Switchgear",
            "duration": "3 days",
            "updateDate": "27 Sept, 2023",
            "shortDescription": "Smart, energy-efficient device for reactive power compensation in low-voltage systems, enhancing power factors and minimizing losses.",
            "intro": "Designed with the aid of computer technology, our GGJ Low Voltage Reactive Power Compensation Device features intelligent tracking and compensation for reactive power. It's a state-of-the-art, energy-efficient solution that improves power factors and reduces losses in low voltage electrical systems.",
            "features": {
                "0": "Smart Controller Control.",
                "1": "Full-featured, reliable performance with an automatic compensation method.",
                "2": "Can enhance the power factor to above 0.9.",
                "3": "Real-time display of the power grid's power factor; display range: lagging (0.00-0.99) to leading (0.00-0.99).",
                "4": "Equipped with comprehensive protection features including over-voltage, harmonic overloading, overcompensation, system failures, phase failures, and overload.",
                "5": "Retains set parameters; system outages won't lead to parameter loss. Once the power grid is back to normal, it auto-enters operation mode, eliminating the need for personnel oversight.",
                "6": "Depending on the power grid load balance, it can use phase compensation or mixed compensation.",
                "7": "Strong anti-interference capability, able to withstand direct interference pulses of 2000V amplitude from the power grid, exceeding national professional standards.",
                "8": "Real-time measurement and on-the-hour recording of three-phase voltage, current, frequency, active power, reactive power, power factor, active energy, and reactive energy on the transformer's low voltage side. Measures total harmonic distortion of voltage and current and records 2-25 harmonic content.",
                "9": "Equipped with RS-232 and RS-485 interfaces; supports handheld computer data recording. Also features remote communication capabilities for wireless meter reading, device testing, parameter setting, and real-time measurement data and recorded data retrieval.",
                "10": "Data analysis functionality: Processes and statistically queries operational load data; comprehensively analyzes power supply quality, calculating voltage pass rate, power supply load rate, reliability, and maximum load rate. Provides time-segmented queries of power factor, active power, and reactive power. Illustrates curves of phase voltage, current, and power factor, and can print comprehensive analysis and statistical reports."
            },
            "technicalParameters": {
                "0": "Rated voltage: 0.38-0.66kV",
                "1": "Rated frequency: 50Hz",
                "2": "Rated capacity: 1-600kvar",
                "3": "Max allowed current: 1.3 times the rated current",
                "4": "Control circuit: 1-16 circuits",
                "5": "Switching time: 1-150S per time",
                "6": "Operating mode: Automatic, continuous operation"
            }
        },
        "5": {
            "name": "Low Voltage Withdrawable Switchgear",
            "model": "GCS",
            "category": "Switchgear",
            "duration": "3 days",
            "updateDate": "27 Sept, 2023",
            "shortDescription": "GCS Low Voltage Withdrawable Switchgear is well-suited for power plants and high-rises. It offers a comprehensive solution for distributing power effectively.",
            "intro": "The GCS-type low-voltage withdrawable switchgear is suitable for power distribution systems in industries such as power plants, oil and gas, chemicals, metallurgy, textiles, and high-rise buildings. In large power plants, petrochemical systems, and other places with a high degree of automation that require interfacing with computers, it serves as a complete low-voltage power distribution device used in power generation and supply systems with a three-phase AC frequency of 50 (60) Hz, rated working voltages of 400V and 660V, and rated currents up to 5000A. It is utilized for power distribution, centralized motor control, and reactive power compensation.",
            "condition": {
                "0": "Ambient air temperature should not exceed +40℃, and not go below -5℃. The average temperature within 24 hours should not exceed +35℃. If exceeded, capacity operation must be reduced based on the actual situation.",
                "1": "For indoor use.",
                "2": "The installation site's altitude should not exceed 2000m.",
                "3": "The surrounding air's relative humidity should not exceed 50% when the temperature is at its highest of +40℃. A higher relative humidity is permissible at lower temperatures, e.g., 90% at +20℃. The potential for occasional condensation due to temperature fluctuations should be considered.",
                "4": "When installing the device, the inclination relative to the vertical plane should not exceed 5°, and the entire cabinet row should be relatively level (compliant with GBJ232-82 standards).",
                "5": "The device should be installed in locations without severe vibrations or impacts and where electrical components are not at risk of undesired corrosion.",
                "6": "For any special requirements, users can consult and customize solutions with our company."
            },
            table: {
                headers: ["Serial No.", "Parameter Name", "Value"],
                rows: [
                    ["1", "Main Circuit Rated Voltage (V)", "AC 400/660"],
                    ["2", "Auxiliary Circuit Rated Voltage", "AC 220, 380 (400), DC 110, 220"],
                    ["3", "Rated Frequency (Hz)", "50 (60)"],
                    ["4", "Rated Insulation Voltage (V)", "660"],
                    ["5", "Rated Current (A) - Horizontal Bus", "≤5000"],
                    ["", "Rated Current (A) - Vertical Bus (MCC)", "1000"],
                    ["6", "Busbar Rated Short-Time Withstand Current (KA/1s)", "50, 80"],
                    ["7", "Busbar Rated Peak Withstand Current (KA/0.1s)", "105, 176"],
                    ["8", "Power-Frequency Test Voltage (V/1min) - Main Circuit", "2500"],
                    ["", "Power-Frequency Test Voltage (V/1min) - Auxiliary Circuit", "2000"],
                    ["9", "Busbar - Three-Phase Four-Wire System", "A.B.C.PEN"],
                    ["", "Busbar - Three-Phase Five-Wire System", "A.B.C.PE.N"],
                    ["10", "Protection Level", "IP30, IP40"],
                ],
            },
        },
        "6": {
            "name": "Dry-type Transformer",
            "model": "SCB11",
            "category": "Transformer",
            "duration": "7 days",
            "updateDate": "27 Sept, 2023",
            "shortDescription": "Our dry-type transformers excel in safety and efficiency, ideal for high-rises, airports, ports, and CNC machinery.",
            "intro": "Ideal for local lighting, high-rise buildings, airports, ports, and CNC machinery, our dry-type transformers offer safety, efficiency, and ease of use.",
            "features": {
                "0": "Safe, fire-resistant, pollution-free, and can be directly operated at the load center.",
                "1": "Adopts domestic advanced technology, high mechanical strength, strong short-circuit resistance, minimal partial discharge, good thermal stability, high reliability, and long service life.",
                "2": "Low loss, low noise, evident energy-saving effects, and maintenance-free.",
                "3": "Good heat dissipation performance, strong overload capacity; forced air cooling can enhance the operational capacity.",
                "4": "Good moisture resistance, suitable for operation in high humidity and other harsh environments.",
                "5": "Dry-type transformers are equipped with a comprehensive temperature detection and protection system. The intelligent signal temperature control system can automatically detect and display the working temperature of each phase winding and can automatically start and stop the fan. It also has settings for alarms, tripping, and other functions.",
                "6": "Compact in size, lightweight, requires minimal installation space, and has low installation costs."
            },
            "condition": {
                "0": "Operating temperature: 0-40°C.",
                "1": "Relative humidity: <70%.",
                "2": "Altitude: No higher than 2,500 meters.",
                "3": "Avoid operation in environments with corrosive liquids, gases, dust, conductive fibers, or a significant amount of metal shavings.",
                "4": "Avoid operation in locations with vibrations or electromagnetic interference.",
                "5": "Avoid prolonged upside-down storage and transportation; avoid strong impacts."
            },
            "technicalParameters": {
                "0": "Operating frequency: 50 / 60HZ.",
                "1": "No-load current: < 4%.",
                "2": "Dielectric strength: 20,000V/min without breakdown; Testing instrument: YZ1802 Dielectric Strength Tester (20mA).",
                "3": "Insulation class: Class F (customizable for special grades).",
                "4": "Insulation resistance: ≥2M Ohms; Testing instrument: ZC25B-4 Megohmmeter (<1000 V).",
                "5": "Connection methods: Y/Y, △/Y0, Yo/△, Auto-transformer type (optional).",
                "6": "Allowable temperature rise of the coil: 100K.",
                "7": "Cooling method: Natural air cooling or temperature-controlled automatic cooling.",
                "8": "Noise level: ≤30dB."
            }
        },
        "7": {
            "name": "Oil-immersed Transformer",
            "model": "S11",
            "category": "Transformer",
            "duration": "7 days",
            "updateDate": "27 Sept, 2023",
            "shortDescription": "Our oil-immersed transformers reliably convert voltage for end-users, built for indoor/outdoor use and harsh conditions.",
            "intro": "Our oil-immersed transformers are perfect for transforming voltage, commonly from 10kv or 35kv down to 380V or 220V for end-user applications. Suitable for both indoor and outdoor settings, they are built to withstand harsh conditions.",
            "features": {
                "0": "For oil-immersed power transformers, the low-voltage winding typically adopts a cylindrical structure made of copper foil winding, except for those with smaller capacities, which use copper wire.",
                "1": "The high-voltage winding employs a multi-layer cylindrical structure, ensuring uniform turn distribution, minimal leakage magnetism, high mechanical strength, and strong resistance to short circuits.",
                "2": "The iron core and winding are securely fastened separately. The device's high-voltage section, low-voltage leads, and other fastening parts are equipped with self-locking nuts to prevent them from coming loose. It uses a coreless structure to withstand the bumps during transportation.",
                "3": "The coil and iron core undergo vacuum drying treatment, and transformer oil is filled using vacuum oil filtration technology, significantly minimizing the moisture inside the oil-immersed power transformer.",
                "4": "The oil tank utilizes a corrugated sheet that has a breathing feature, compensating for oil volume changes due to temperature fluctuations. As a result, this product doesn't have an oil storage tank, significantly reducing the transformer's height.",
                "5": "Because the corrugated sheet replaces the oil storage tank, the transformer oil is isolated from the external environment, effectively preventing the ingress of oxygen and water, which could degrade insulation performance.",
                "6": "Based on the above points, there's no need to change the oil of the oil-immersed power transformer during normal operation, greatly reducing maintenance costs and extending the transformer's service life."
            },
            "condition": {
                "0": "Maximum altitude: ≤1500 meters;",
                "1": "Maximum wind speed: 35M/S;",
                "2": "Altitude: no more than 2500 meters;",
                "3": "Maximum relative humidity: ≤90% (at 25℃);",
                "4": "Highest ambient temperature: +45℃;",
                "5": "Average temperature in the hottest month: +30℃;",
                "6": "Lowest ambient temperature: -40℃;",
                "7": "Maximum daily temperature difference: 25℃;",
                "8": "Ice coverage: 10MM;",
                "9": "Seismic ground horizontal acceleration: 0.3g;",
                "10": "Pollution level: Class IV."
            },
            "technicalParameters": {
                "0": "Rated capacity: 50-2000kVA",
                "1": "Rated voltage: 10KV or 35KV",
                "2": "Voltage adjustment: Off-circuit or semi-automatic",
                "3": "Insulation grade: F"
            }
        },
        "8": {
            "name": "Indoor Voltage Transformer",
            "model": "JDZX9-10",
            "category": "CT&PT",
            "duration": "7-15 days",
            "updateDate": "17 Jan, 2024",
            "shortDescription": "Indoor Epoxy Resin Voltage Transformer, Lightweight and Efficient.",
            "intro": "This indoor voltage transformer is an epoxy resin casting full enclosed full insulating product. It is used for voltage measuring, electric energy metering and relay protection. Small size and lightweight, there are secondary terminal box, wiring is convenient.",
            "features": {
                "0": "Utilizes advanced epoxy resin material to ensure complete sealing and insulation, enhancing safety.",
                "1": "Compact design, small in size, facilitates easy installation and space-saving.",
                "2": "Lightweight design, easy to handle and install, reducing installation costs.",
                "3": "Equipped with a secondary terminal box, simplifying the wiring process and improving installation efficiency.",
                "4": "Provides accurate and reliable data for voltage measurement, electric energy metering, and relay protection.",
                "5": "Durable and stable for long-term operation, reducing maintenance costs."
            },
            "condition": {
                "0": "Maximum altitude: ≤1500 meters;",
                "1": "Maximum wind speed: 35M/S;",
                "2": "Altitude: no more than 2500 meters;",
                "3": "Maximum relative humidity: ≤90% (at 25℃);",
                "4": "Highest ambient temperature: +45℃;",
                "5": "Average temperature in the hottest month: +30℃;",
                "6": "Lowest ambient temperature: -40℃;",
                "7": "Maximum daily temperature difference: 25℃;",
                "8": "Ice coverage: 10MM;",
                "9": "Seismic ground horizontal acceleration: 0.3g;",
                "10": "Pollution level: Class IV."
            },
            "technicalParameters": {
                "0": "Rated insulation level: 12/42/75kV",
                "1": "Rated frequency: 50/60Hz",
                "2": "Power factor: COSφ=0.8(lag)",
                "3": "Standard: GB20840.1(2,3,4)"
            }
        },
        "9": {
            "name": "LZZW-10 Current Transformer",
            "model": "LZZW-10",
            "category": "CT&PT",
            "duration": "7-15 day",
            "updateDate": "17 Jan, 2024",
            "shortDescription": "Outdoor Current Transformer, Highly Durable, Suitable for Various Environmental Conditions.",
            "intro": "This outdoor current transformer is an epoxy resin full enclosed pillar product. It is used for electric energy metering, current metering and relay protection. This CT meets the standard of IEC185 and GB1208-2006 <Current transformer>. It has the characteristics of arc resistance, ultraviolet ray resistance, aging resistance, long working life and so on.",
            "features": {
                "0": "Utilizes a fully enclosed pillar structure made of epoxy resin, enhancing durability and safety.",
                "1": "Features excellent arc resistance, ultraviolet ray resistance, and aging resistance, suitable for outdoor use.",
                "2": "Offers various accuracy classes (0.5/0.5S/0.2/0.2S/5P10/10P10) to meet different precision requirements.",
                "3": "Available in red and grey, catering to different visual and environmental requirements.",
                "4": "Suitable for electric energy metering, current measurement, and relay protection, offering multifunctional applications.",
                "5": "Pollution level of Class IV, enhancing reliability in heavily polluted environments."
            },
            "condition": {
                "0": "Maximum altitude: ≤1500 meters;",
                "1": "Maximum wind speed: 35M/S;",
                "2": "Altitude: no more than 2500 meters;",
                "3": "Maximum relative humidity: ≤90% (at 25℃);",
                "4": "Highest ambient temperature: +45℃;",
                "5": "Average temperature in the hottest month: +30℃;",
                "6": "Lowest ambient temperature: -40℃;",
                "7": "Maximum daily temperature difference: 25℃;",
                "8": "Ice coverage: 10MM;",
                "9": "Seismic ground horizontal acceleration: 0.3g;",
                "10": "Pollution level: Class IV."
            },
            "technicalParameters": {
                "0": "Rated voltage: 10kV",
                "1": "Rated frequency: 50/60Hz",
                "2": "Rated primary current: 5~1000A",
                "3": "Rated second current: 5A/1A",
                "4": "Rated insulation level: 12/42/75kV",
                "5": "Accuracy class: 0.5/0.5S/0.2/0.2S/5P10/10P10",
                "6": "Burden: 5VA/10VA/Customized"
            }
        }
    },
    productsText: {
        discuss: "Have products in mind? Let's discuss",
        touch: "Get in touch with us to see how we can help you with your project"
    },
    blog: {
        "1blog": {
            "title": "Ximai Electrical Introduction",
            "tags": {
                "Introduction": "Introduction"
            },
            "description": [
                "Hubei Ximai Electrical Equipment Co. , Ltd (abbreviated as 'Ximai Electrical') was established in 2006 with registered capital of 20 million RMB. The company has over 100 employees, of which more than 90% are engaged in technological production and R&D.",
                "As knowledge-intensive innovative company of strong technical force, and based on talents and technology advantages, Ximai electrical mainly manufactures type SH15, S13, S11 and SCB11 transformers, box - type substation, KYN28 High - voltage switch-cabinet, GCS, GGD, GGJ, power supply cabinet, distribution box, and iron accessory equipment, as well as engaging in general contracting for electrical engineering projects. Product quality management system in line with the ISO9001, environmental management system in line with ISO14001, occupational health and safety management system in line with OHSAS18001, CCC certification, and have passed the National Quality Supervision and Inspection center's qualified test, and we own a patent.",
                "The products have met the State Grid Hubei Electric Power Company's standard, verifying our company's ability of being their qualified supplier. We were awarded as 'Contract - keeping and Credit First Company of E'zhou city in 2011', 'Contract - keeping and Credit First Company of Hubei Province in 2010 - 2011', 'Consumer Satisfaction with the Goods in 2011 - 2012', 'Contract - keeping and Credit First Company of E'zhou city in 2012 - 2013', 'Contract - keeping and Credit First Company of Hubei Province in 2014'. Meanwhile, our brand was honored as 'Well - known Trademark of E'zhou City from 2014 - 2018', and 'Famous Trademark of Hubei Province from 2014 - 2019'.",
                "Through the introduction of advanced concepts, the use of sophisticated technology, our company gets successful results in the State Grid tender projects: such as the State Grid Hubei Electric Power Company's first supermarket - style centralized procurement (iron fittings and bolts) in 2015, the State Grid Hubei Electric Power Company's power distribution network equipment protocol stock (new batch), the State Grid En'Shi Electric Power Company's selenium tea City II (25 - 36 F #) power supply engineering (material procurement and supervision), Hubei Electric power company's peak summer emergency repair works of rural network in 2012, Hubei Electric power company's rural power project, Hubei Electric power company's supporting project for the new residential construction from November 2011 to November 2012, Hubei Electric power company's power distribution network project for rural power upgrading of Suizhou city in 2011, Hubei Electric power company's supporting project for new residential construction and project for urban low-voltage distribution network from December 2010 to December 2011, Hubei Electric power company's fifth batch rural power upgrading project (network distribution) for Guangshui, Suizhou city in 2010. The equipment's operation conditions have obtained the user's universal acclaim.",
                "Guided by the principle of 'based in China while serve the whole world', and combined with market demand, Ximai Electrical concentrates on new product development, product structure optimizing, energy conservation and environmental protection. Providing new, energy - saving, environmentally friendly products is our unswerving pursuit. With 'safety first, quality-oriented, customer first' as our aim, we strive to establish good reputation and provide integrity services.",
                "Welcome your arrival and instruction, and all the staff of Ximai Electrical await your presence!"
            ],
            "author": "Qianyi Yu",
            "authorTitle": "Qianyi Yu",
            "quote": "Explore Ximai Electrical through our introduction video, know more about Ximai and the decision of ensuring the quality of products.",
            "blockquote": "",
            "create_at": "15 APR, 2020",
            "blClass": "format-video"
        },
        "2blog": {
            "title": "Specialized Electrical Supply for Lingkong District People's Hospital",
            "tags": {
                "Medical_Infrastructure": "Medical Infrastructure",
                "Electrical_Engineering": "Electrical Engineering",
                "Urban_Development": "Urban Development"
            },
            "description": [
                "We are proud to announce our recent collaboration with the Lingkong District People's Hospital, where we have undertaken the specialized electrical supply project. Ensuring consistent and efficient power supply to healthcare institutions is of paramount importance. With state-of-the-art machinery and critical patient care systems relying heavily on electricity, a robust and reliable power infrastructure becomes a cornerstone of modern healthcare.",
                "Our experienced team has meticulously designed and implemented a power supply system that aligns with the unique needs of the hospital. From emergency rooms to operating theaters, we have ensured that each department receives an uninterrupted power supply, minimizing risks and elevating patient care standards.",
                "This venture signifies our deep commitment to enhancing public health infrastructure. We understand the challenges of urban healthcare facilities and are dedicated to providing solutions that meet the high standards expected of modern medical establishments.",
                "As we look forward to more collaborations in the future, our endeavor with Lingkong District People's Hospital stands as a testament to our expertise and commitment to community welfare."
            ],
            "author": "Qianyi Yu",
            "authorTitle": "Qianyi Yu",
            "quote": "Empowering healthcare with specialized electrical solutions. At the heart of medical excellence is robust infrastructure.",
            "blockquote": "",
            "create_at": "11 AUG, 2021",
            "blClass": "format-standard-image"
        },
        "3blog": {
            "title": "Power Distribution Renovation at Ezhou No.1 Hospital",
            "tags": {
                "Infrastructure_Upgrade": "Infrastructure Upgrade",
                "Medical_Infrastructure": "Medical Infrastructure"
            },
            "description": [
                "We are immensely honored to announce our recent involvement in the power distribution renovation project at the Ezhou No.1 Hospital.",
                "Our dedicated and skilled team embarked on this project with a clear vision - to enhance the efficiency and reliability of the hospital's electrical system. Through meticulous planning, cutting-edge solutions, and an unwavering commitment to excellence, we have successfully elevated the hospital's power distribution to new heights.",
                "Such projects underline the importance of a robust and efficient electrical system, especially in healthcare settings where uninterrupted power is crucial. With a history of successfully completed projects, our team was well-poised to undertake this responsibility. We worked in close collaboration with the hospital's management, ensuring minimal disruption to their operations. The upgraded electrical system now not only promises efficient power distribution but also offers better safety, durability, and future readiness.",
                "We recognize the vital role power distribution plays in the smooth running of such essential institutions and remain dedicated to providing unparalleled service. As always, our endeavors are driven by a commitment to our clients, the community, and the desire to make a meaningful impact.",
                "This achievement serves as a testament to our unwavering dedication and technical prowess. We are deeply grateful to the management of Ezhou No.1 Hospital for entrusting us with this responsibility and look forward to more collaborative efforts in the future."
            ],
            "author": "Qianyi Yu",
            "authorTitle": "Qianyi Yu",
            "quote": "Delivering unparalleled expertise to power crucial institutions like Ezhou No.1 Hospital.Our commitment to excellence ensures a brighter, safer future for all.",
            "blockquote": "",
            "create_at": "15 APR, 2023",
            "blClass": "format-standard-image"
        },
        "4blog": {
            "title": "Power Capacity Expansion for the Isolation Area at Ezhou University During the COVID-19",
            "tags": {
                "COVID-19": "COVID-19",
                "University_Infrastructure": "University Infrastructure",
                "Electrical_Capacity_Upgrade": "Electrical Capacity Upgrade",
                "Social_Responsibility": "Social Responsibility"
            },
            "description": [
                "We are proud to announce that Hubei Ximai Electrical Equipment Co., Ltd. rose to the occasion during the pandemic by increasing the power capacity for the isolation area at Ezhou University. In times of crisis, our dedication to serving the community truly shines.",
                "With the outbreak of the pandemic, the requirement for power at the university's isolation area surged. Our adept team of professionals, armed with the expertise in power capacity expansion, worked diligently and swiftly to meet these increased demands. It was crucial that the institution could rely on a robust and stable power supply to ensure the smooth running of the isolation area.",
                "We understand the enormous responsibility that such an endeavor holds, particularly during a pandemic. Through meticulous planning, state-of-the-art equipment, and relentless teamwork, we successfully enhanced the power capacity, ensuring the continuous operation of the university's isolation area.",
                "Amid the ever-evolving world of electrical engineering, the need for adaptable, efficient, and resilient solutions is more pressing than ever. Our involvement with Ezhou University's project serves as a testament to our ability to respond to unique challenges promptly and effectively.",
                "Our commitment to serving our clients and the broader community during challenging times remains unwavering. We express our gratitude to Ezhou University for trusting us during these testing times, and we look forward to further collaborative efforts."
            ],
            "author": "Qianyi Yu",
            "authorTitle": "Qianyi Yu",
            "quote": "Strengthening power capacity, enhancing resilience during challenging times. Hubei Ximai Electrical stands with the community during the pandemic.",
            "blockquote": "",
            "create_at": "25 APR, 2023",
            "blClass": "format-standard-image"
        },
        "5blog": {
            "title": "Street Lighting Project on Binjiang 2nd Road, Ezhou",
            "tags": {
                "Street_Lighting": "Street Lighting",
                "Urban_Infrastructure": "Urban Infrastructure",
                "Urban_Development": "Urban Development"
            },
            "description": [
                "We are immensely proud to have played a pivotal role in the Street Lighting Project on Binjiang 2nd Road in Ezhou, as part of the broader Ezhou Riverside Flood Control Ecological Restoration Project.",
                "Our involvement spanned the gamut of project services – from trench excavation, pole erection, light fixture and accessory installation, to cable laying. Upholding the highest standards of engineering quality and a steadfast commitment to integrity in service, we continually strive to offer our clients top-notch engineering solutions.",
                "Within the scope of this project, we also took on the active responsibility of maintaining and safeguarding client-provided materials and facilities. This encompassed tasks such as site clearance, sludge treatment, and water pumping, ensuring a smooth and successful execution of the project from inception to completion. As we move forward, our dedication remains intact, and we are geared to provide professional and reliable services and support for various engineering projects."
            ],
            "author": "Qianyi Yu",
            "authorTitle": "Qianyi Yu",
            "quote": "Illuminating the streets of Ezhou with precision and expertise, paving the way for safer, brighter urban spaces.",
            "blockquote": "",
            "create_at": "15 APR, 2023",
            "blClass": "format-standard-image"
        },
        "6blog": {
            "title": "Emergency Electrical Repairs During the COVID-19 Period",
            "tags": {
                "Emergency_Response": "Emergency Response",
                "Electrical_Repairs": "Electrical Repairs",
                "Social_Responsibility": "Social Responsibility",
                "COVID-19": "COVID-19"
            },
            "description": [
                "During the challenging times of the 2020 Covid-19, our company actively participated in emergency electrical repairs, demonstrating our commitment to social responsibility. The pandemic disrupted various facets of daily life, and amidst this chaos, ensuring uninterrupted power supply became more critical than ever before.",
                "Our dedicated team was on the front lines, working diligently to address electrical disruptions and restore normalcy to affected areas. Such actions not only ensured that essential services remained operational but also underscored our company's unwavering dedication to community welfare. As the pandemic raged on, our swift interventions alleviated potential hardships, reinforcing the trust our community has in our expertise and service.",
                "These unparalleled efforts during such unprecedented times speak volumes about our company's ethos, values, and our pledge to rise to any occasion, no matter how challenging."
            ],
            "author": "Qianyi Yu",
            "authorTitle": "Qianyi Yu",
            "quote": "Powering through adversity, ensuring light during the darkest hours. Our commitment stood unyielding amidst the 2020 pandemic.",
            "blockquote": "",
            "create_at": "6 MAY, 2020",
            "blClass": "format-standard-image"
        },
        "7blog": {
            "title": "Power Distribution Room Renovation at Leishan Hospital During the Pandemic",
            "tags": {
                "COVID-19": "COVID-19",
                "Emergency_Response": "Emergency Response",
                "Social_Responsibility": "Social Responsibility",
                "Medical_Infrastructure": "Medical Infrastructure"
            },
            "description": [
                "During the pandemic, our company heeded the call to action and joined the fight against the virus. We undertook the power distribution room renovation project at Leishan Hospital in Ezhou, providing the hospital with a stable and reliable power supply.",
                "Our professional technical team, after multiple rounds of deliberation and research, designed a comprehensive power distribution solution for the hospital. Adhering strictly to the relevant standards and requirements, we executed the renovation project meticulously. Throughout this process, we became acutely aware of how every healthcare worker's and patient's safety and health relies on the support of a reliable power supply.",
                "Our engineers worked tirelessly, day and night, on the renovation frontlines, ensuring the quality and progress of the project. Our employees always kept the company's social responsibility in mind, practicing the values we have always held with their actions.",
                "We will continue to pay attention to societal issues, respond actively to social calls, and contribute to building a better society."
            ],
            "author": "Qianyi Yu",
            "authorTitle": "Qianyi Yu",
            "quote": "Amidst a global crisis, our dedication shone through. Our commitment to serve, evident as we powered healthcare facilities and lit the beacon of hope.",
            "blockquote": "",
            "create_at": "18 JUL, 2020",
            "blClass": "format-standard-image"
        },
        "8blog": {
            "title": "Power Distribution Room Renovation at Ezhou No.3 Hospital During the Pandemic",
            "tags": {
                "Crisis_Response": "Crisis Response",
                "Professional_Services": "Professional Services",
                "Medical_Infrastructure": "Medical Infrastructure",
                "COVID-19": "COVID-19",
            },
            "description": [
                "Our company provided comprehensive services for the power distribution room renovation at Ezhou No.3 Hospital, fully embodying the sense of corporate social responsibility. Against the backdrop of the 2020 COVID-19 pandemic, our professional technical team went deep into the hospital site. With a scientific and reasonable power distribution plan and precise construction, we provided the hospital with a stable and reliable power supply.",
                "Our employees overcame various difficulties and challenges brought about by the pandemic, demonstrating the spirit and responsibility of disaster relief and epidemic prevention. The successful implementation of this project not only showcased our professional standards and sense of social responsibility but also strengthened our belief in practicing corporate social responsibility.",
                "We will continue to create greater value for our customers and society with higher standards and more professional services."
            ],
            "author": "Qianyi Yu",
            "authorTitle": "Qianyi Yu",
            "quote": "During testing times, we rose to the occasion. Powering healthcare facilities and serving our community, we showcased our unwavering commitment and resilience.",
            "blockquote": "",
            "create_at": "03 AUG, 2020",
            "blClass": "format-standard-image"
        },
        "9blog": {
            "title": "Delivering Coolness: Initiative by Our Project Team",
            "tags": {
                "Social_Responsibility": "Social Responsibility",
                "Employee_Welfare": "Employee Welfare",
            },
            "description": [
                "In the midst of a sweltering summer, our company initiated a 'Delivering Coolness' campaign for the employees of China First Metallurgical Construction Corporation. We provided the employees with refreshing drinks and fresh fruit, bringing a sense of relief and surprise.",
                "Our company has always adhered to a 'people-oriented' service philosophy, putting customer needs and experience first. Through this campaign, we hoped to convey the corporate culture of 'love, care, warmth,' allowing more people to feel our dedication and concern.",
                "We will continue to strive to provide our clients with higher quality services and support, contributing to the construction of a better society."
            ],
            "author": "Qianyi Yu",
            "authorTitle": "Qianyi Yu",
            "quote": "Cooling off the summer heat, we extended our care and warmth to our employees. Upholding our 'people-first' philosophy, we continue striving for excellence in service.",
            "blockquote": "",
            "create_at": "07 AUG, 2022",
            "blClass": "format-standard-image"
        },
        "10blog": {
            "title": "On-site Emergency Repairs During the COVID-19 Pandemic",
            "tags": {
                "Emergency_Response": "Emergency Response",
                "COVID-19": "COVID-19",
                "Field_Service": "Field Service",
                "Social_Responsibility": "Social Responsibility"
            },
            "description": [
                "During the challenging times of the COVID-19 pandemic in 2021, our team displayed remarkable resilience and commitment. We were at the forefront, providing on-site emergency repair services, ensuring uninterrupted power supply when it was needed the most.",
                "The pandemic brought unforeseen challenges, but our team stood strong. We followed stringent safety protocols and worked tirelessly to keep critical infrastructure running smoothly. These emergency repairs ranged from fixing critical power line issues to restoring and maintaining crucial electrical equipment.",
                "Our field service teams worked round-the-clock, sometimes in harsh and demanding conditions. Their undeterred commitment and expertise ensured minimal downtime and maintained the continuity of essential services.",
                "As a responsible organization, we understand our role in such challenging times. It's our duty to provide uninterrupted service and ensure that electrical failures do not add to the difficulties faced by our community.",
                "We are incredibly proud of our teams who risked their own well-being for the greater good. Their dedication and courage are commendable, and they truly embody the spirit of our company.",
                "Despite the ongoing challenges posed by the pandemic, we remain committed to delivering our services with the same determination and quality. We stand ready to face any future challenges and continue serving our community."
            ],
            "author": "Qianyi Yu",
            "authorTitle": "Qianyi Yu",
            "quote": "Through resilience and commitment, our team stepped up during the pandemic to ensure uninterrupted power supply. At the frontlines of service, we are ready to face any challenge.",
            "blockquote": "",
            "create_at": "21 MAY, 2021",
            "blClass": "format-standard-image"
        },
        "11blog": {
            "title": "Development of Lingkong Avenue Bus Stop and Trash Can Solutions",
            "tags": {
                "Infrastructure": "Infrastructure",
                "Public_Transportation": "Public Transportation",
                "Urban_Development": "Urban Development"
            },
            "description": [
                "We're elated to unveil our comprehensive contributions to Lingkong Avenue's urban infrastructure, encompassing the Bus Stop and the state-of-the-art Trash Can Solutions. Our expertise in urban development has been showcased in these two critical projects, emphasizing our dedication to enhancing community experiences and environmental sustainability.",
                "The newly designed Lingkong Avenue Bus Stop is a paradigm of modern transportation infrastructure, aiming to provide residents and travelers with an efficient, comfortable, and safe commuting experience. In parallel, our innovative trash can solutions are engineered to handle urban waste effectively, supporting the city's cleanliness and environmental goals.",
                "We believe that robust transportation and sanitation infrastructures play pivotal roles in uplifting urban life quality. Our team's in-depth collaboration with local authorities and stakeholders ensures that these installations not only cater to present-day needs but are also adaptable for future demands.",
                "We remain fervently committed to shaping urban landscapes that are convenient, clean, and sustainable. Our continued association with such transformative projects stands as a testament to our vision and capability. We eagerly anticipate further collaborations to enhance urban life."
            ],
            "author": "Qianyi Yu",
            "authorTitle": "Qianyi Yu",
            "quote": "Crafting a harmonious urban experience with advanced transportation and sanitation solutions.",
            "blockquote": "",
            "create_at": "26 OCT, 2022",
            "blClass": "format-standard-image"
        }
    }
}

export default en_translation;